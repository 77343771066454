import React from "react";
import { connect } from "react-redux";
import { getSession } from "../../config/session";
// import api from "../../config/api";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";

import {
  getProductCategoriesAllV2,
  addProductCategory,
  uploadPhoto,
  removePhoto,
} from "../../layouts/Admin/actions/ProductCategoryActions";
import {
  getProductById,
  getInventory,
  getStocks,
  addReconciliation,
  getReconciliationByid,
  updateReconciliation,
  publishReconciliation,
  declineReconciliation,
} from "../../layouts/Admin/actions/InventoryActions";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Table,
  Row,
  Col,
  Input,
  Label,
  Badge,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import queryString from "query-string";

class EditReconciliation extends React.Component {
  constructor(props) {
    super(props);
    const dateToday = format(new Date(), "MMMM d, yyyy");
    const reconciliationId = props.match.params._id;
    const defaultPage = JSON.parse(getSession("defaultPage"));

    const userData = JSON.parse(getSession("userData"));
    let userInfo = {};
    if (userData != null) {
      userInfo = userData.info;
    }
    this.state = {
      todayDate: dateToday,
      reconciliationId: reconciliationId,
      reconciliation: {
        products: [],
        reconciliationDate: "",
        description: "",
        status: "",
        preparedBy: userData && userData.userId ? userData.userId : "",
        place: defaultPage,
        pickedDate: "",
        totalVarianceByUnit: [],
      },
      isFiccoManager:
        userData && userData.isFiccoManager ? userData.isFiccoManager : false,
      inventoryProducts: [],
      selectedProduct: null,
      addedProduct: null,
      isLoading: false,
      isSaving: false,
      submitted: false,
      hideTutorials: false,
      addProductModalError: "",
      submittedModal: false,
      modal: false,
      user: {
        name: userInfo.firstName + " " + userInfo.lastName,
      },
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));

    if (userData == null) {
      this.props.history.push("/login");
      window.location.reload();
    }

    const sessionToken = userData.sessionToken;

    const reconciliationId = this.state.reconciliationId;
    this.props.getReconciliationByid(
      reconciliationId,
      sessionToken,
      (err, res) => {
        if (!err && res) {
          const reconciliation = {
            description: res.description,
            place: res.place,
            user: res.user,
            createdAt: res.createdAt,
            products: res.products,
            status: res.status,
            totalVarianceByUnit: res.products.reduce((acc, product) => {
              const volumeType = product.unit || "";
              const varianceValue = product.difference || 0;

              if (!acc[volumeType]) {
                acc[volumeType] = 0;
              }
              acc[volumeType] += varianceValue;
              return acc;
            }, {}),
            pickedDate: res.reconciliationDate
              ? new Date(res.reconciliationDate)
              : null,
            //  reconciliationDate: res.reconciliationDate,
          };

          this.setState({
            reconciliation: reconciliation,
            dateToday: res.createdAt,
            inventoryProducts: res.products,
          });
        }
      }
    );
  }

  showNotification(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }

  showNotificationError(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }

  handleChangeAddProduct = (e) => {
    let { name, value } = e.target;

    this.setState({
      addedProduct: {
        ...this.state.addedProduct,
        [name]: value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));
    const reconciliationId = this.state.reconciliationId;

    if (userData) {
      const sessionToken = userData.sessionToken;
      const products = this.state.inventoryProducts.map((item) => ({
        id: item.id,
        name: item.name,
        kind: item.kind,
        unit: item.unit,
        volume: {
          id: item.volume && item.volume.id,
          description: item.volume && item.volume.description,
          value: item.volume && item.volume.value,
        },
        variation: {
          id: item.variation && item.variation.id,
          description: item.variation && item.variation.description,
        },
        stockCount: item.stockCount,
        physicalCount: !isNaN(parseInt(item.physicalCount))
          ? parseInt(item.physicalCount)
          : 0,
        remarks: item.remarks,
      }));
      const reconciliation = {
        ...this.state.reconciliation,
        products: products,
      };

      let hasError = false;
      if (reconciliation) {
        this.setState({ submitted: true });
        if (
          !reconciliation.pickedDate ||
          products.some((p) => !p.remarks || p.remarks.trim() === "")
        ) {
          hasError = true;
          this.showNotificationError(
            "Some fields are required. Please fill in the required fields"
          );
        }

        // if (products.some((p) => p.physicalCount === 0)) {
        //   hasError = true;
        //   this.showNotificationError(
        //     "Physical count should not be 0 for any product"
        //   );
        // }
        if (!hasError) {
          if (!window.confirm("Do you want to save this item?")) {
            return false;
          }
          this.setState({ isSaving: true });

          this.props.updateReconciliation(
            reconciliationId,
            reconciliation,
            sessionToken,
            (err, res) => {
              if (res) {
                this.setState({ submitted: false, isSaving: false });
                if (res && res.status === "success") {
                  this.showNotification("Reconciliation has been updated");
                  setTimeout(() => {
                    this.props.history.push("/inventory-reconciliation");
                  }, 3000);
                }
              } else {
                if (err) {
                  this.setState({ submitted: false, isSaving: false });
                  const { response } = err;
                  let msg = "";
                  if (typeof response === "string") msg = response;
                  else {
                    if (
                      response.data !== null &&
                      response.data.message !== null &&
                      typeof response.data.message === "string"
                    ) {
                      msg = response.data.message;
                    }
                  }
                  this.setState({ addSupplierModalError: msg });
                } else {
                  this.setState({
                    submitted: false,
                    isSaving: false,
                    addSupplierModalError:
                      "An unknown error occured. Please try again.",
                  });
                }
              }
            }
          );
        }
      } else {
        this.setState({ submitted: true });
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields"
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };

  handlePublish = (e) => {
    if (!window.confirm("Do you want to publish this reconciliation?")) {
      return false;
    }
    const productId = this.state.reconciliationId;
    const userData = JSON.parse(getSession("userData"));
    let userInfo = {};
    if (userData != null) {
      userInfo = userData.info;
    }
    const publishedBy = {
      publishedBy: userData && userData.userId ? userData.userId : "",
    };

    if (productId) {
      const userData = JSON.parse(getSession("userData"));
      if (userData) {
        const sessionToken = userData.sessionToken;
        this.props.publishReconciliation(
          productId,
          publishedBy,
          sessionToken,
          (error, result) => {
            if (!error && result) {
              console.log(result);
              if (result.status === 204) {
                this.showNotification(
                  "Reconciliation has been published successfully"
                );
                setTimeout(() => {
                  this.props.history.push("/inventory-reconciliation");
                }, 3000);
              }
            } else {
              if (error) {
                if (
                  error.response &&
                  error.response.status &&
                  error.response.status === 403
                ) {
                  setTimeout(() => {
                    this.setState({ isLoading: true });
                  }, 1000);
                  this.showNotificationError(
                    "You are not allowed to publish this reconciliation."
                  );
                } else {
                  this.showNotificationError(error.response);
                }
              }
            }
          }
        );
      } else {
        this.props.history.push("/login");
        window.location.reload();
      }
    }
  };
  handleDecline = (e) => {
    if (!window.confirm("Do you want to decline this reconciliation?")) {
      return false;
    }
    const productId = this.state.reconciliationId;
    const userData = JSON.parse(getSession("userData"));
    let userInfo = {};
    if (userData != null) {
      userInfo = userData.info;
    }
    const declinedBy = {
      declinedBy: userData && userData.userId ? userData.userId : "",
    };

    if (productId) {
      const userData = JSON.parse(getSession("userData"));
      if (userData) {
        const sessionToken = userData.sessionToken;
        this.props.declineReconciliation(
          productId,
          declinedBy,
          sessionToken,
          (error, result) => {
            if (!error && result) {
              if (result.status === 204) {
                this.showNotification(
                  "Reconciliation has been declined successfully"
                );
                setTimeout(() => {
                  this.props.history.push("/inventory-reconciliation");
                }, 3000);
              }
            } else {
              if (error) {
                if (
                  error.response &&
                  error.response.status &&
                  error.response.status === 403
                ) {
                  setTimeout(() => {
                    this.setState({ isLoading: true });
                  }, 1000);
                  this.showNotificationError(
                    "You are not allowed to decline this reconciliation."
                  );
                } else {
                  this.showNotificationError(error.response);
                }
              }
            }
          }
        );
      } else {
        this.props.history.push("/login");
        window.location.reload();
      }
    }
  };
  onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  handleChangeProduct = (e) => {
    const { name, value } = e.target;
    if (name === "description") {
      this.setState({
        reconciliation: {
          ...this.state.reconciliation,
          description: value,
        },
      });
      return;
    }

    const index = e.currentTarget.dataset.idx;

    if (index !== null) {
      let inventoryProducts = [...this.state.inventoryProducts];

      const parsedValue =
        name === "physicalCount" ? parseFloat(value) || 0 : value;

      inventoryProducts[index] = {
        ...inventoryProducts[index],
        [name]: parsedValue,
      };

      // Calculate variance if physicalCount is changed
      if (name === "physicalCount") {
        const stockCount = inventoryProducts[index].stockCount || 0; // Default to 0 if stockCount is undefined
        const variance = stockCount - parsedValue;
        inventoryProducts[index].difference = variance;
      }

      // Calculate total variance by unit type
      const totalVarianceByUnit = inventoryProducts.reduce((acc, product) => {
        const volumeType = (product && product.unit) || "";
        const varianceValue = product.difference || 0; // Ensure we're using 'variance' here

        if (!acc[volumeType]) {
          acc[volumeType] = 0;
        }

        acc[volumeType] += varianceValue;
        return acc;
      }, {});

      // Update state
      this.setState({
        inventoryProducts,
        reconciliation: {
          ...this.state.reconciliation,
          totalVarianceByUnit,
        },
      });
    }
  };

  handleDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

    this.setState({
      reconciliation: {
        ...this.state.reconciliation,
        pickedDate: date,
        reconciliationDate: formattedDate,
      },
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    this.setState({
      reconciliation: {
        ...this.state.reconciliation,
        description: value,
      },
    });
  };
  renderRows(inventoryProducts) {
    if (this.state.isLoading) {
      return (
        <tr>
          <td colSpan={7}>
            <PulseLoader
              sizeUnit={"px"}
              size={15}
              color={"#1d8cf8"}
              loading={this.state.isLoading}
            />
          </td>
        </tr>
      );
    } else {
      if (inventoryProducts instanceof Array && inventoryProducts.length > 0) {
        return inventoryProducts.map((item, index) => (
          <tr key={index}>
            <td className="text-medium">{item.name}</td>
            <td className="text-medium">{item.variation.description}</td>
            <td className="text-medium">{item.volume.description}</td>
            <td>{item.stockCount}</td>
            <td>
              <FormGroup
              // className={
              //   (this.state.submitted &&
              //     !this.state.inventoryProducts[index].physicalCount) ||
              //   this.state.inventoryProducts[index].physicalCount === 0
              //     ? " has-danger"
              //     : ""
              // }
              >
                <InputGroup>
                  <Input
                    name="physicalCount"
                    placeholder="Physical Count"
                    type="text"
                    value={
                      this.state.inventoryProducts[index].physicalCount || 0
                    }
                    data-idx={index}
                    onChange={this.handleChangeProduct}
                  />
                  <InputGroupText
                  // className={
                  //   (this.state.submitted &&
                  //     !this.state.inventoryProducts[index].physicalCount) ||
                  //   this.state.inventoryProducts[index].physicalCount === 0
                  //     ? " has-danger"
                  //     : ""
                  // }
                  >
                    {this.state.inventoryProducts[index].unit || ""}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
            </td>

            <td width="200">
              <FormGroup>
                <Input
                  name="variance"
                  readOnly
                  placeholder="Variance"
                  type="number"
                  value={this.state.inventoryProducts[index].difference || 0}
                  data-idx={index}
                />
              </FormGroup>
            </td>
            <td width="300">
              <FormGroup
                className={
                  this.state.submitted &&
                  !this.state.inventoryProducts[index].remarks
                    ? " has-danger"
                    : ""
                }
              >
                <Input
                  name="remarks"
                  placeholder="Remarks"
                  type="text"
                  value={this.state.inventoryProducts[index].remarks || ""}
                  data-idx={index}
                  onChange={this.handleChangeProduct}
                />
              </FormGroup>
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={7}>
              <h5 className="text-danger">
                <em>No item(s) found.</em>
              </h5>
            </td>
          </tr>
        );
      }
    }
  }

  renderReconciliationForm(reconciliation) {
    // let { submitted } = this.state;
    return (
      <>
        {" "}
        <Row>
          <Col sm="12">
            <Row>
              <Col lg="12" md="12" sm="12">
                <FormGroup>
                  <Alert color="primary">Edit reconciliation details.</Alert>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="3" sm="6">
                <FormGroup>
                  <label htmlFor="slug" className="control-label">
                    Date Created
                    <em className="text-muted"></em>
                  </label>
                  <Input
                    readOnly
                    name="createdDate"
                    className="createdDate"
                    placeholder="Date Created"
                    type="text"
                    defaultValue={this.state.todayDate}
                  />
                </FormGroup>
              </Col>{" "}
              <Col lg="3" md="3" sm="6">
                <FormGroup
                  className={
                    this.state.submitted && !reconciliation.pickedDate
                      ? " has-danger"
                      : ""
                  }
                >
                  <label htmlFor="name" className="control-label">
                    As of
                  </label>
                  <DatePicker
                    selected={reconciliation.pickedDate}
                    onChange={this.handleDateChange}
                    dateFormat="MMMM d, yyyy"
                    className="form-control"
                    placeholderText="Select date"
                  />
                </FormGroup>
              </Col>
              <Col lg="3" md="3" sm="6">
                <FormGroup>
                  <label htmlFor="slug" className="control-label">
                    Prepared By
                    <em className="text-muted"></em>
                  </label>
                  <Input
                    readOnly
                    name="time"
                    className="time"
                    placeholder="Time"
                    type="text"
                    defaultValue={this.state.user.name}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label htmlFor="description" className="control-label">
                    Description
                  </Label>
                  <Input
                    id="description"
                    name="description"
                    placeholder="Description"
                    type="textarea"
                    value={this.state.reconciliation.description} // Ensure value comes from the correct state
                    onChange={this.handleChange}
                    style={{ height: "100px" }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <p className="control-label">Product</p>
                  <Row>
                    <Col>
                      <Table
                        style={{ minWidth: "600px" }}
                        className="tablesorter table-hover"
                        responsive
                        size="sm"
                      >
                        <thead className="text-primary">
                          <tr>
                            <th>Product Name</th>
                            <th>Variation</th>

                            <th>Volume</th>
                            <th>Stock Count</th>
                            <th>Physical Count</th>
                            <th>Difference/Variance</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderRows(this.state.inventoryProducts)}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <br />
                  <Col md="12">
                    <Row className="pull-right">
                      <Label htmlFor="productType" className="control-label">
                        TOTAL VARIANCE:&nbsp;
                      </Label>
                      <span align="center">
                        {Object.entries(
                          this.state.reconciliation.totalVarianceByUnit || []
                        ).map(([volumeType, totalVariance]) => (
                          <span align="center">
                            <Badge color="primary" pill>
                              {totalVariance} {volumeType}
                            </Badge>{" "}
                          </span>
                        ))}
                        {/* <Badge color="primary" pill>
                          0.5 kg
                        </Badge>
                        <Badge color="primary" pill>
                          {this.state.reconciliation &&
                          this.state.reconciliation.totalVariance
                            ? this.state.reconciliation.totalVariance
                            : 0}{" "}
                          pcs
                        </Badge> */}
                      </span>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
  render() {
    const pageInfo = JSON.parse(getSession("pageInfo"));
    if (pageInfo && pageInfo._id) {
      return (
        <>
          <div className="content">
            <div className="react-notification-alert-container">
              <NotificationAlert ref="notify" />
            </div>
            <Row>
              <Col sm="12" md="12" lg="12">
                <Card>
                  <Form
                    onSubmit={this.handleSubmit}
                    onKeyPress={this.onKeyPress}
                  >
                    <CardHeader>
                      <h4 className="title">Edit Reconciliation</h4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          {this.renderReconciliationForm(
                            this.state.reconciliation
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    {this.state.isFiccoManager === false && (
                      <CardFooter className="pull-right">
                        {this.state.reconciliation.status === "unpublished" && (
                          <Button
                            type="submit"
                            className="btn-round"
                            color="info"
                          >
                            Save
                          </Button>
                        )}

                        <Link
                          to="/inventory-reconciliation"
                          className="btn btn-round btn-light"
                        >
                          Cancel
                        </Link>
                      </CardFooter>
                    )}
                    {this.state.reconciliation &&
                      this.state.reconciliation.status === "unpublished" &&
                      this.state.isFiccoManager === true && (
                        <CardFooter className="pull-right">
                          <Link
                            to="#"
                            className="btn btn-round btn-danger"
                            onClick={this.handleDecline}
                          >
                            Decline
                          </Link>
                          <Link
                            to="#"
                            type="submit"
                            className="btn btn-round btn-info"
                            onClick={this.handlePublish}
                          >
                            Publish
                          </Link>
                        </CardFooter>
                      )}
                    {this.state.reconciliation &&
                      this.state.reconciliation.status === "published" &&
                      this.state.isFiccoManager === true && (
                        <CardFooter className="pull-right">
                          <Link
                            to="/inventory-reconciliation"
                            className="btn btn-round btn-light"
                          >
                            Cancel
                          </Link>
                        </CardFooter>
                      )}
                  </Form>
                </Card>
              </Col>
            </Row>
            <LoadingOverlay
              active={this.state.isSaving}
              spinner
              text="Saving..."
            ></LoadingOverlay>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getProductCategoriesAllV2,
  addProductCategory,
  uploadPhoto,
  removePhoto,
  getProductById,
  getInventory,
  getStocks,
  addReconciliation,
  getReconciliationByid,
  updateReconciliation,
  publishReconciliation,
  declineReconciliation,
})(EditReconciliation);
