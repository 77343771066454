import {
  GET_PRODUCTS,
  GET_PRODUCT,
  GET_PRODUCT_TYPES,
  GET_INVENTORY,
  GET_PRICE_LOGS,
  GET_PRICE_LOG,
} from "../../../reducers/products/types";

import api from "../../../config/api";
import apiV2 from "../../../config/apiV2";

export const getProductsByPageId =
  (pageId, query, token, cb) => (dispatch, getState) => {
    api(token)
      .get("provider/places/" + pageId + "/products", { params: query })
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: GET_PRODUCTS,
            payload: response.data,
          });
          return cb(null, response.data);
        }
        cb(response, null);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const getProductsGroceryByPageId =
  (pageId, query, token, cb) => (dispatch, getState) => {
    api(token)
      .get("provider/places/" + pageId + "/grocery-products", { params: query })
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: GET_PRODUCTS,
            payload: response.data,
          });
          return cb(null, response.data);
        }
        cb(response, null);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const getProductsByProviderId =
  (query, token, cb) => (dispatch, getState) => {
    api(token)
      .get("/provider/products", { params: query })
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: GET_PRODUCTS,
            payload: response.data,
          });
          return cb(null, response.data);
        }
        cb(response, null);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const getProductById =
  (productId, token, cb) => (dispatch, getState) => {
    api(token)
      .get("/provider/products/" + productId)
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: GET_PRODUCT,
            payload: response.data,
          });
          return cb(null, response.data);
        }
        cb(response, null);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const addProduct = (product, token, cb) => (dispatch, getState) => {
  const body = product;
  api(token)
    .post("/business/products", body)
    .then((response) => {
      if (response && response.data) {
        return cb(null, response.data);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const updateProduct =
  (payload, productId, token, cb) => (dispatch, getState) => {
    const body = payload;
    api(token)
      .patch(`/business/products/${productId}`, body)
      .then((response) => {
        if (response && response.data) {
          return cb(null, response.data);
        }
        cb(response, null);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const deleteProduct = (productId, token, cb) => (dispatch, getState) => {
  api(token)
    .delete(`/business/products/${productId}`)
    .then((response) => {
      if (response && response.data) {
        return cb(null, response.data);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getProductTypes = (cb) => (dispatch, getState) => {
  api()
    .get("/provider/product-types")
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: GET_PRODUCT_TYPES,
          payload: response.data,
        });
        return cb(null, response.data);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

// Get Page Categories (productcategories)
export const getProductCategories = (token, cb) => () => {
  api(token)
    .get(`/product-categories/all`)
    .then((response) => {
      if (response && response.data) {
        return cb(null, response.data);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

// Get NEW Page Categories (placeproductcategories)
export const getProductCategoriesV2 = (placeId, query, token, cb) => () => {
  api(token)
    .get(`/places/${placeId}/product-categories/all`, { params: query })
    .then((response) => {
      if (response && response.data) {
        return cb(null, response.data);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const uploadPhoto =
  (productId, data, token, cb) => (dispatch, getState) => {
    api(token)
      .post(`/business/products/${productId}/media`, data)
      .then((response) => {
        if (response && response.data) return cb(null, response.data);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const uploadVariationPhoto =
  (productId, data, token, cb) => (dispatch, getState) => {
    api(token)
      .post(`/business/products/${productId}/variation-photos`, data)
      .then((response) => {
        if (response && response.data) return cb(null, response.data);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const removePhoto =
  (productId, photoId, token, cb) => (dispatch, getState) => {
    api(token)
      .delete(`business/products/${productId}/media/${photoId}`)
      .then((response) => {
        if (response && response.data) return cb(null, response.data);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const getOrdersList = (query, token, cb) => () => {
  api(token)
    .get("/provider/orders", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getProductOrders = (query, token, cb) => () => {
  api(token)
    .get("/provider/product-orders", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const uploadPhotoByProductName =
  (productName, data, token, cb) => (dispatch, getState) => {
    api(token)
      .post(`/business/products/uploadPhotoByProductName/${productName}`, data)
      .then((response) => {
        if (response && response.data) return cb(null, response.data);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const getSalesList = (query, token, cb) => () => {
  api(token)
    .get("/provider/salesV2", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getProductOrdersGrocery = (query, token, cb) => () => {
  api(token)
    .get("/provider/grocery/product-orders", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getOrdersListGrocery = (query, token, cb) => () => {
  api(token)
    .get("/provider/grocery/orders", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getSalesListGrocery = (query, token, cb) => () => {
  api(token)
    .get("/provider/grocery/sales", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getSalesListXls = (query, token, cb) => () => {
  api(token)
    .get("/provider/sales-list-xls", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getSalesListGroceryXls = (query, token, cb) => () => {
  api(token)
    .get("/provider/sales-list-grocery-xls", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getOrderListXls = (query, token, cb) => () => {
  api(token)
    .get("/provider/orders-list-xls", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getProductOrdersXls = (query, token, cb) => () => {
  api(token)
    .get("/provider/product-orders-xls", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getProductOrdersGroceryXls = (query, token, cb) => () => {
  api(token)
    .get("/provider/product-orders-grocery-xls", { params: query })
    .then((response) => {
      if (response) {
        return cb(null, response);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const merchantApiLogin =
  (endpoint, username, password, token, cb) => () => {
    const body = {
      endpoint: endpoint,
      username: username,
      password: password,
    };

    api(token)
      .post("/business/merchant-api-login", body)
      .then((response) => {
        if (response && response.data) {
          return cb(null, response.data);
        }
        cb(response, null);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const importProductsFromApi =
  (endpoint, apiKey, placeId, token, cb) => () => {
    const body = {
      endpoint: endpoint,
      apiKey: apiKey,
      placeId: placeId,
    };

    api(token)
      .post("/business/import-products-from-api", body)
      .then((response) => {
        if (response && response.data) {
          return cb(null, response.data);
        }
        cb(response, null);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const syncProductsFromAPI =
  (endpoint, apiKey, placeId, token, cb) => () => {
    const body = {
      endpoint: endpoint,
      apiKey: apiKey,
      placeId: placeId,
    };

    api(token)
      .post("/business/sync-products-from-api", body)
      .then((response) => {
        if (response && response.data) {
          return cb(null, response.data);
        }
        cb(response, null);
      })
      .catch((error) => {
        cb(error, null);
      });
  };

export const syncProductsFromJSON = (products, placeId, token, cb) => () => {
  const body = {
    products,
    placeId,
  };

  api(token)
    .post("/business/sync-products-from-json", body)
    .then((response) => {
      if (response && response.data) {
        return cb(null, response.data);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getInventory = (query, token, cb) => (dispatch, _) => {
  apiV2(token)
    .get("/products", { params: query })
    .then((response) => {
      if (response && response.data) {
        dispatch({
          type: GET_INVENTORY,
          payload: response.data,
        });
        return cb(null, response.data);
      }
      cb(response, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getPriceLogs = (productId, query, token, cb) => (dispatch, _) => {
  apiV2(token)
    .get(`/inventory/products/${productId}/price-logs`, { params: query })
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: GET_PRICE_LOGS,
          payload: res,
        });
        return cb(null, res.data);
      }
      cb(res, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const publishPriceLog = (priceId, token, cb) => () => {
  apiV2(token)
    .post(`/inventory/price-logs/${priceId}/publish`, {})
    .then((resp) => {
      if (resp) return cb(null, resp);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const unPublishPriceLog = (priceId, token, cb) => () => {
  apiV2(token)
    .post(`/inventory/price-logs/${priceId}/unpublish`, {})
    .then((resp) => {
      if (resp) return cb(null, resp);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const getPriceLogById = (priceId, token, cb) => (dispatch, _) => {
  apiV2(token)
    .get("/inventory/price-logs/" + priceId)
    .then((resp) => {
      if (resp && resp.data) {
        dispatch({
          type: GET_PRICE_LOG,
          payload: resp.data,
        });
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const updatePriceLog = (priceId, payload, token, cb) => () => {
  const body = payload;
  apiV2(token)
    .patch(`/inventory/price-logs/${priceId}`, body)
    .then((resp) => {
      if (resp) return cb(null, resp);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const getRestoredList = (placeId, query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/places/${placeId}/restored-products`, { params: query })
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((err) => {
      cb(err, null);
    });
};

export const addRestored = (reject, token, cb) => () => {
  const body = reject;
  apiV2(token)
    .post("/inventory/restored-products", body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const updateResored = (restoredId, restored, token, cb) => () => {
  const body = restored;
  apiV2(token)
    .patch(`/inventory/restored-products/${restoredId}`, body)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};
export const getProductRestoreById = (restoredId, token, cb) => () => {
  apiV2(token)
    .get("/inventory/products/" + restoredId)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const getRestoredId = (restoredId, token, cb) => () => {
  apiV2(token)
    .get("/inventory/restored-products/" + restoredId)
    .then((resp) => {
      if (resp && resp.data) {
        return cb(null, resp.data);
      }
      cb(resp, null);
    })
    .catch((error) => {
      cb(error, null);
    });
};

export const deleteRestored = (markdownId, token, cb) => () => {
  apiV2(token)
    .delete(`/inventory/restored-products/${markdownId}`)
    .then((resp) => {
      cb(null, resp);
    })
    .catch((error) => {
      cb(error, null);
    });
};

//Reports
export const exportRestoredList = (query, token, cb) => () => {
  apiV2(token)
    .get(`/inventory/restored-products-xlsx`, {
      params: query,
      responseType: "blob",
    })
    .then((resp) => {
      if (resp && resp.status === 200 && resp.data) {
        const blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob and trigger download
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // link.download = `ExportMarkdown_${query.someIdentifier}.xlsx`; // Adjust filename as needed

        link.download = `ExportRestoredFile.xlsx`; // Adjust filename as needed
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        // Callback with no error and the response data
        cb(null, resp.data);
      } else {
        // Callback with an error if the response is not as expected
        cb(new Error("Failed to download file"), null);
      }
    })
    .catch((err) => {
      cb(err, null);
    });
};
