import axios from "axios";

let url = "http://localhost:5001";
if (process.env.NODE_ENV === "production")
  url = "https://inventory.1coopmarketplace.com"; // production

const instance = (token) => {
  const apiV2 = axios.create({
    baseURL: url,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return apiV2;
};

export default instance;
