import React from "react";
import { connect } from "react-redux";
import { getSession, setSession } from "../../config/session";
import api from "../../config/api";
import { Link } from "react-router-dom";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";

import {
  getProductTypes,
  addRestored,
} from "../../layouts/Admin/actions/ProductActions";
import { getRejects } from "../../layouts/Admin/actions/InventoryActions";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Table,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

class AddRestoreProduct extends React.Component {
  constructor(props) {
    const defaultPage = JSON.parse(getSession("defaultPage"));

    super(props);
    this.state = {
      product: {
        name: "",
        description: "",
        productType: "",
        forSale: false,
        isActive: false,
        unit: "",
        kind: "",
        limit: "",
        autofullfill: false,
        price: 0,
        comparePrice: "",
        placeId: defaultPage,
      },
      expirationDate: "",
      pickedDate: "",
      rejectProducts: [],
      rejectProductsById: [],
      productTypes: [],
      productCategories: [],
      places: [],
      checked: [],
      expanded: [],
      isLoading: false,
      submitted: false,
      submittedHighlight: false,
      isSaving: false,
      isGrocery: false,
      pageType: "food-drink",
      activeStep: "product-info",
      steps: {
        productInfo: true,
      },
      hideTutorials: false,
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const pageId = pageInfo && pageInfo._id ? pageInfo._id : "";
    const { sessionToken } = userData;
    if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
      // If pageType Grocery
      this.setState({ isGrocery: true });
      this.setState({ pageType: "grocery" });
    } else if (pageInfo && pageInfo.pageType === "5dea2304f6bba08323a3ddce") {
      // If pageType SB Tours
      this.setState({ pageType: "sb-tours" });
    }

    if (userData !== null) {
      this.setState({
        hideTutorials:
          pageInfo && pageInfo.hideTutorials && pageInfo.hideTutorials === true
            ? true
            : false,
      });
      this.props.getProductTypes((error, result) => {
        if (!error && result) {
          this.setState({ productTypes: result });
        }
      });
      let queryStr = "?";
      this.props.getRejects(pageId, queryStr, sessionToken, (err, res) => {
        if (!err && res) {
          this.setState({
            rejectProducts: res.docs && res.docs[0],
            rejectProductsById: res.docs && res.docs[0] && res.docs[0].products,
          });
          console.log("PRODUCTS", res.docs[0].products);
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  }

  handleChange = (e) => {
    let { name, value, type } = e.target;
    if (type === "checkbox") {
      const checked = e.target.checked;
      value = checked;
    }
    if (name === "kind") {
      let unit = "pc.";
      if (value === "crops") {
        unit = "kg.";
      }
      const product = this.state.product;
      product.unit = unit;
      this.setState({
        product: {
          ...this.state.product,
          unit: unit,
        },
      });
    }
    if (name === "price" || name === "comparePrice") {
      const floatValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
      this.setState({
        product: {
          ...this.state.product,
          [name]: floatValue,
        },
      });
    } else
      this.setState({
        product: {
          ...this.state.product,
          [name]: value,
        },
      });
  };

  handleChangeSection = (e) => {
    let { name, value } = e.target;
    this.setState({
      section: {
        ...this.state.section,
        [name]: value,
      },
    });
  };

  onDismiss = () => {
    this.setState({ hideTutorials: true });
    let pageInfo = JSON.parse(getSession("pageInfo"));
    pageInfo.hideTutorials = true;
    setSession("pageInfo", JSON.stringify(pageInfo));
  };

  onHideTutorials = () => {
    if (
      !window.confirm("Are you sure you want to hide this section permanently?")
    ) {
      return false;
    }
    let pageInfo = JSON.parse(getSession("pageInfo"));
    let userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const place = {
      hideTutorials: true,
    };
    api(sessionToken)
      .patch(`/provider/places/${pageInfo._id}`, place)
      .then((response) => {
        if (response && response.data && response.data.status === "ok") {
          this.setState({ hideTutorials: true });
          pageInfo.hideTutorials = true;
          setSession("pageInfo", JSON.stringify(pageInfo));
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      })
      .catch((error) => {
        this.setState({ submitted: false, isSaving: false });
        if (error.response && typeof error.response === "string") {
          this.showNotificationError(error.response);
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      });
  };

  showNotification(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }

  showNotificationError(message) {
    if (message) {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(notification);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const sessionToken = userData.sessionToken;
    if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
      // If pageType Grocery
      this.setState({ isGrocery: true });
    }

    if (userData) {
      const product = { ...this.state.product };
      const rejectProducts = [...this.state.rejectProductsById];

      if (
        product.name &&
        product.productType &&
        this.state.expirationDate &&
        product.kind &&
        !isNaN(product.price) &&
        product.price > 0
        // product.placeId
      ) {
        if (!window.confirm("Do you want to save this item?")) {
          return false;
        }

        this.setState({ submitted: true, isSaving: true });

        // if (product) {
        //   product.tags = [];
        //   product.categories = [];
        //   product.productCategories = [];
        //   product.options = {
        //     DineIn: false,
        //     PickUp: false,
        //     Delivery: false,
        //   };
        //   product.containerFee = {
        //     supportedOrderOption: {
        //       pickup: false,
        //       delivery: false,
        //     },
        //     amount: 0,
        //   };
        // }
        const payload = {
          product,
          originProducts: rejectProducts,
          rejectID: this.state.rejectProducts.id,
          restored: "true",
          remarks: "",
          place: product.placeId,
          expirationDate: this.state.expirationDate,
          user: userData && userData.userId ? userData.userId : "",
        };
        console.log(payload);
        this.props.addRestored(payload, sessionToken, (error, result) => {
          if (result) {
            console.log("RESULT", result);
            // setSession("hasProducts", true);
            this.setState({ submitted: false, isSaving: false });

            if (result.id) {
              this.showNotification("New restored product has been added.");
              setTimeout(() => {
                this.props.history.push(
                  `/product-restored/${result.id}/edit?message=Complete the product by adding photos &amp; category etc.`
                );
              }, 3000);
            } else {
              this.showNotification("New restored product has been added.");
              setTimeout(() => {
                this.props.history.push("/product-restored");
              }, 3000);
            }
          } else {
            if (error) {
              this.setState({ submitted: false, isSaving: false });
              // this.showNotificationError(error.response);
            } else {
              this.setState({ submitted: false, isSaving: false });
              this.showNotificationError(
                "An unknown error occured. Please try again."
              );
            }
          }
        });
      } else {
        this.setState({ submitted: true });
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields."
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  renderProductTypes() {
    if (
      this.state.productTypes !== undefined ||
      this.state.productTypes !== null
    ) {
      const activeProductTypes = this.state.productTypes.filter(
        (item) => item.isActive
      );
      return activeProductTypes.map((item, index) => (
        <option key={index} value={item._id}>
          {item.name}
        </option>
      ));
    }
  }

  renderPlaces() {
    if (this.state.places !== undefined || this.state.places !== null) {
      return this.state.places.map((item, index) => (
        <option key={index} value={item.place._id}>
          {item.place.name}
        </option>
      ));
    }
  }
  handleChangeProduct = (e) => {
    const { value, dataset } = e.target;
    const index = dataset.idx;
    const quantity = parseFloat(value) || 0; // Parse quantity as a number

    // Clone the rejects array from state to safely update
    const updatedRejects = [...this.state.rejectProductsById];

    // Check if the quantity is within the allowed range
    if (quantity <= updatedRejects[index].volumeRejected) {
      // Update the volumeRestored value if valid
      updatedRejects[index].volumeReclaimed = quantity;
    } else {
      // Optionally, show an error or alert if quantity exceeds volumeRejected
      alert("Quantity cannot exceed the available volume rejected.");

      return;
    }

    // Set the updated state
    this.setState({ rejectProductsById: updatedRejects });
  };

  handleDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

    this.setState({
      pickedDate: date,
      expirationDate: formattedDate,
    });
  };
  renderVerticalNavBar() {
    return (
      <Nav vertical>
        <NavItem>
          <NavLink
            className={
              "nav-link-vertical" +
              (this.state.activeStep && this.state.activeStep === "product-info"
                ? " active-link"
                : "")
            }
            to="#"
            onClick={() => {
              this.setState({ activeStep: "product-info" });
            }}
          >
            Product Information
            <span style={{ float: "right" }}>
              <Fa icon="chevron-right" />
            </span>
          </NavLink>
        </NavItem>
      </Nav>
    );
  }
  renderRows(rejects) {
    if (this.state.isLoading) {
      return (
        <tr>
          <td colSpan={7}>
            <PulseLoader
              sizeUnit={"px"}
              size={15}
              color={"#1d8cf8"}
              loading={this.state.isLoading}
            />
          </td>
        </tr>
      );
    } else {
      if (rejects instanceof Array && rejects.length > 0) {
        return rejects.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>

            <td>{item.volume && item.variation.description}</td>

            <td>{item.volume && item.volume.description}</td>

            <td className="text-medium">
              <FormGroup>
                <InputGroup>
                  <Input
                    name="volumeRejected"
                    placeholder="Available Volume"
                    type="text"
                    readOnly
                    value={item.volumeRejected ? item.volumeRejected : 0}
                    data-idx={index}
                  />
                  <InputGroupText>
                    {item.unit ? item.unit : "pc(s)."}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
            </td>
            <td className="text-medium">
              <FormGroup>
                <InputGroup>
                  <Input
                    name="volumeReclaimed"
                    placeholder="Quantity"
                    type="text"
                    data-idx={index}
                    // value={item.volumeReclaimed ? item.volumeReclaimed : ""}
                    onChange={this.handleChangeProduct}
                  />
                  <InputGroupText
                  // className={
                  //   (this.state.submitted &&
                  //     !this.state.inventoryProducts[index].physicalCount) ||
                  //   this.state.inventoryProducts[index].physicalCount === 0
                  //     ? " has-danger"
                  //     : ""
                  // }
                  >
                    {item.unit ? item.unit : "pc(s)."}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={7}>
              <h5 className="text-danger">
                <em>No item(s) found.</em>
              </h5>
            </td>
          </tr>
        );
      }
    }
  }

  renderProductInfoStep(product) {
    return (
      <>
        <Row>
          <Col lg="12" md="12" sm="12">
            <FormGroup>
              <Alert color="primary">
                Add basic information of the restore product.
              </Alert>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="3" sm="6">
            <FormGroup
              className={
                this.state.submitted && !this.state.expirationDate
                  ? " has-danger"
                  : ""
              }
            >
              <label htmlFor="name" className="control-label">
                Expiration Date <em className="text-muted">(Required)</em>
              </label>
              <DatePicker
                selected={this.state.pickedDate}
                onChange={this.handleDateChange}
                dateFormat="MMMM d, yyyy"
                className="form-control"
                placeholderText="Select date"
              />
            </FormGroup>
          </Col>
          <Col lg="4" md="4" sm="6">
            <FormGroup
              className={
                this.state.submitted && !this.state.product.kind
                  ? " has-danger"
                  : ""
              }
            >
              <Label htmlFor="kind" className="control-label">
                Kind <em className="text-muted">(Required)</em>
              </Label>
              <Input
                id="kind"
                name="kind"
                className="kind"
                placeholder="Kind"
                type="select"
                value={product.kind}
                onChange={this.handleChange}
              >
                <option value="">Select one</option>
                <option value="crops">Crops</option>
                <option value="goods">Goods</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg="4" md="4" sm="6">
            <FormGroup>
              <Label htmlFor="unit" className="control-label">
                Unit
              </Label>
              <Input
                name="uni2"
                className="unit"
                placeholder="Unit"
                type="text"
                value={product.unit}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="6" md="6" sm="12">
            <FormGroup
              className={
                this.state.submitted && !product.name ? " has-danger" : ""
              }
            >
              <Label htmlFor="name" className="control-label">
                Product Name <em className="text-muted">(Required)</em>
              </Label>
              <Input
                id="name"
                name="name"
                className="name"
                placeholder="Product Name"
                type="text"
                value={product.name}
                onChange={this.handleChange}
                autocomplete="name"
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6" sm="12">
            <FormGroup
              className={
                this.state.submitted && !product.productType
                  ? " has-danger"
                  : ""
              }
            >
              <Label htmlFor="productType" className="control-label">
                Product Type <em className="text-muted">(Required)</em>
              </Label>
              <Input
                id="productType"
                name="productType"
                className="productType"
                placeholder="Product Type"
                type="select"
                value={product.productType}
                onChange={this.handleChange}
              >
                <option value="">Select One</option>
                {this.renderProductTypes()}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label htmlFor="description" className="control-label">
                Description
              </Label>
              <Input
                id="description"
                name="description"
                placeholder="Description"
                type="textarea"
                value={product.description}
                onChange={this.handleChange}
                style={{ height: "150px" }}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Row>
            <Col>
              <Table
                style={{ minWidth: "600px" }}
                className="tablesorter table-hover"
                responsive
                size="sm"
              >
                <thead className="text-primary">
                  <tr>
                    <th>Product Name</th>
                    <th>Variation</th>
                    <th>Volume</th>
                    <th>Available Resotred Volume</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>{this.renderRows(this.state.rejectProductsById)}</tbody>
              </Table>
            </Col>
          </Row>
        </FormGroup>

        <Row>
          <Col md="4">
            <FormGroup
              className={
                this.state.submitted &&
                (!product.price || isNaN(product.price) || product.price <= 0)
                  ? " has-danger"
                  : ""
              }
            >
              <Label htmlFor="price" className="control-label">
                Price <em className="text-muted">(Required)</em>
              </Label>
              <Input
                id="price"
                name="price"
                className="price"
                placeholder="&#8369;"
                type="text"
                value={product.price}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label htmlFor="comparePrice" className="control-label">
                Compare Price
              </Label>
              <Input
                id="comparePrice"
                name="comparePrice"
                className="comparePrice"
                placeholder="&#8369;"
                type="text"
                value={product.comparePrice}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }

  renderNoPageAdded() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">No Page Added</h4>
                  <hr />
                  <p className="mb-0">
                    You have not added a page yet. Click{" "}
                    <Link to="/add-page">here</Link> to add a new page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderPageNotVerified() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">
                    You Have Not Agreed with our Terms & Policies
                  </h4>
                  <hr />
                  <p className="mb-0">
                    You must agree with our Terms & Policies. Click{" "}
                    <Link to="/statement-of-agreement">here</Link> to read our
                    Terms & Policies.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderPageDisabled() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">This page is disabled.</h4>
                  <hr />
                  <p className="mb-0">
                    Click <Link to="/products">here</Link> to go back to the
                    main page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    let { pageType } = this.state;
    let categoriesTree = [];
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const productCategories = this.state.productCategories;
    if (productCategories instanceof Array && productCategories.length > 0) {
      const parentCategories = productCategories.filter((item) => !item.parent);
      const childCategories = productCategories.filter((item) => item.parent);
      if (parentCategories) {
        parentCategories.forEach((cat) => {
          const value = cat._id.toString();
          const label = cat.name;
          let sublist = [];
          if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
            // If Grocery
            sublist = childCategories.filter(
              (item) => item.parent._id.toString() === value
            );
          } else {
            sublist = childCategories.filter(
              (item) => item.parent._id.toString() === value
            );
          }
          let children1 = [];
          if (sublist instanceof Array && sublist.length > 0) {
            let category1 = [];
            sublist.forEach((cat) => {
              const value = cat._id;
              const label = cat.name;
              const category = {
                value: value,
                label: label,
                children: [],
              };
              category1.push(category);
            });
            children1 = category1;
          }
          const children = children1;
          const category = {
            value: value,
            label: label,
            children: children,
          };
          categoriesTree.push(category);
        });
      }
    }

    if (pageInfo && pageInfo._id) {
      if (pageInfo.isVerified === true) {
        if (pageType !== "sb-tours") {
          return (
            <>
              <div className="content">
                <div className="react-notification-alert-container">
                  <NotificationAlert ref="notify" />
                </div>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <Card>
                      <Form
                        onSubmit={this.handleSubmit}
                        onKeyPress={this.onKeyPress}
                      >
                        <CardHeader>
                          <h4 className="title">Add New Restore Product</h4>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            {!pageInfo.hideTutorials && (
                              <Col sm="12">
                                <Row>
                                  <Col md="12">
                                    <Alert
                                      className="alert-compact"
                                      color="primary"
                                      isOpen={!this.state.hideTutorials}
                                      toggle={this.onDismiss}
                                      fade={false}
                                    >
                                      <h4 className="alert-heading">
                                        New on Products?
                                      </h4>
                                      <hr />
                                      <p className="mb-0">
                                        Check our videos here on how to manage
                                        your products.
                                        <br />
                                      </p>
                                      <a
                                        className="btn btn-sm btn-primary-v2"
                                        href="https://www.youtube.com/watch?v=k8VEDqbPv7w"
                                      >
                                        View Tutorials
                                      </a>
                                      <Button
                                        className="btn-fill btn-sm"
                                        color="danger"
                                        type="button"
                                        onClick={this.onHideTutorials}
                                      >
                                        Hide Permanently
                                      </Button>
                                    </Alert>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col sm="12" md="3" lg="3">
                              {this.renderVerticalNavBar()}
                            </Col>
                            <Col sm="12" md="9" lg="9">
                              {this.state.activeStep === "product-info" &&
                              this.state.steps.productInfo === true
                                ? this.renderProductInfoStep(this.state.product)
                                : this.renderProductInfoStep(
                                    this.state.product
                                  )}
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <Button
                            className="btn-round"
                            color="info"
                            type="submit"
                          >
                            Save
                          </Button>
                          <Link
                            to="/product-restored"
                            className="btn btn-round btn-light"
                          >
                            Cancel
                          </Link>
                        </CardFooter>
                      </Form>
                    </Card>
                  </Col>
                </Row>
                <LoadingOverlay
                  active={this.state.isSaving}
                  spinner
                  text="Saving..."
                ></LoadingOverlay>
              </div>
            </>
          );
        } else if (pageType && pageType === "sb-tours") {
          return this.renderPageDisabled();
        }
      } else {
        return this.renderPageNotVerified();
      }
    } else {
      return this.renderNoPageAdded();
    }
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getProductTypes,
  addRestored,
  getRejects,
})(AddRestoreProduct);
