import axios from "axios";

let url = "http://localhost:5000";

if (process.env.NODE_ENV === "production")
  url = "https://www.1coopmarketplace.com"; // production

const instance = (token) => {
  const api = axios.create({
    baseURL: url,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return api;
};

export default instance;
